import Axios, { AxiosInstance } from 'axios'

export interface HttpHeaders {
  [key: string]: string
}

export interface HttpParams {
  [key: string]: string
}

export interface HttpOptions {
  headers: HttpHeaders
  params: HttpParams
}

export interface IHttpClient {
  get(url: string, options?: HttpOptions): Promise<any>

  post(url: string, body: object, options?: HttpOptions): Promise<any>
}


export class HttpClient implements IHttpClient {
  private axios: AxiosInstance

  constructor() {
    const token = this.getCookie('token')
    
    this.axios = Axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": token
      }
    })
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  get<T>(url: string, options?: HttpOptions): Promise<T> {
    return this.axios.get(url, {
      headers: HttpClient.getHeadersFromOptions(options),
      params: HttpClient.getParamsFromOptions(options),
      transformResponse: (data) => {
        return JSON.parse(data)
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (!error.response) {
          console.log('Error: Network Error')
        } else {
          console.log(error.response.data.message)
        }
      })
  }

  post<T> (url: string, body: object, options?: HttpOptions): Promise<T> {
    return this.axios.post(url, body, {
      headers: HttpClient.getHeadersFromOptions(options),
      params: HttpClient.getParamsFromOptions(options),
      transformResponse: (data) => {
        return JSON.parse(data)
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (!error.response) {
          console.log('Error: Network Error')
        } else {
          console.log(error.response.data.message)
        }
      })
  }

  private static getHeadersFromOptions(options?: HttpOptions): HttpHeaders {
    if (options && options.headers) {
      return options.headers
    }
    return {}
  }

  private static getParamsFromOptions(options?: HttpOptions): HttpParams {
    if (options && options.params) {
      return options.params
    }
    return {}
  }
}

export default new HttpClient()
