






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PercentText extends Vue {
    @Prop() private percent!: number
}
