import Vue from 'vue'
import Vuex from 'vuex'
import { Currency } from './models/Currency'
import { Exchange } from './models/Exchange'
import { Market } from './models/Market'
import { Strategy } from './models/strategy'
import BackEndService from './services/BackEndService'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currencies: Array<Currency>(),
        exchanges: Array<Exchange>(),
        markets: Array<Market>(),
        strategies: Array<Strategy>(),
    },
    
    getters: {
        allCurrencies: (state) => state.currencies,
        allExchanges: (state) => state.exchanges,
        allMarkets: (state) => state.markets,
        exchangeById: (state) => (exchangeId: number) => state.exchanges.find(x => x.id == exchangeId),
        marketsByExchange: (state) => (exchangeId: number) => state.markets.filter(x => x.exchangeId == exchangeId).sort(compareMarkets),
        marketById: (state) => (marketId: number) => state.markets.find(x => x.id == marketId),
        currencyById: (state, currencyId) => state.currencies.find(x => x.id == currencyId),        
        allStrategies: (state) => state.strategies,
    },
  
    mutations: {
        setCurrencies(state, currencies: Array<Currency>) {
            state.currencies = currencies
        },
        setExchanges(state, exchanges: Array<Exchange>) {
            state.exchanges = exchanges
        },
        setMarkets(state, markets: Array<Market>) {
            state.markets = markets
        },
        setStrategies(state, strategies: Array<Strategy>) {
            state.strategies = strategies
        }
    },

    actions: {
        async getCurrencies() {
            if (this.state.currencies.length == 0) {
                const currencies = await BackEndService.GetCurrencies()
                this.commit('setCurrencies', currencies)
                return currencies
            }
            return this.state.currencies
        },
        async getExchanges() {
            if (this.state.exchanges.length == 0) {
                const exchanges = await BackEndService.GetExchanges()
                this.commit('setExchanges', exchanges)
                return exchanges
            }
            return this.state.exchanges
        },
        async getMarkets() {
            if (this.state.markets.length == 0) {
                const markets = await BackEndService.GetMarkets(true)
                markets.forEach(x => setMarketSymbolAndCurrencies(x, this.state.currencies))                

                this.commit('setMarkets', markets)
                return markets
            }
            return this.state.markets
        },
        async getStrategies() {
            if (this.state.strategies.length == 0) {
                const strategies = await BackEndService.GetStrategies()             

                this.commit('setStrategies', strategies)
                return strategies
            }
            return this.state.markets
        }
    }
})

function setMarketSymbolAndCurrencies(market: Market, currencies: Currency[]) {
    let baseCurrency = currencies.find(x => x.id == market.base)
    let quoteCurrency = currencies.find(x => x.id == market.quote)
    market.baseCurrency = baseCurrency
    market.quoteCurrency = quoteCurrency
    market.symbol = baseCurrency?.code + '/' + quoteCurrency?.code
}

function compareMarkets(market1: Market, market2: Market) {
    if (market1.base < market2.base) {
        return -1
    } else if (market1.base > market2.base) {
        return 1
    } else {
        if (market1.quote < market2.quote) {
            return -1
        } else if (market1.quote > market2.quote) {
            return 1
        } else {
            return 0
        }
    }
}