












import { Component, Vue } from 'vue-property-decorator'
import CoinChartHighCharts from '@/components/CoinChartHighcharts.vue'
import BackEndService from '@/services/BackEndService'
import { Exchange } from '@/models/Exchange'
import { Market } from '@/models/Market'
import { Currency } from '@/models/Currency'
import { Indicator } from '@/models/Indicator'

@Component({
  components: { CoinChartHighCharts }
})
export default class Test extends Vue {
  private exchanges: Exchange[] = [] //= [{ id: null, name: '-- Select exchange --', disabled: true }]
  private currencies: Currency[] = []
  private markets: Market[] = [] //= [{ id: null, symbol: '-- Select market --', disabled: true }]
  private indicators: Indicator[] = []
  private selectedExchange: number = 1
  private selectedMarket: number = 1
  private selectedIndicator: number = 1

  private market: Market | null = null
  private indicator: Indicator | null = null

  created () {
    BackEndService.GetExchanges().then((data) => {
      this.exchanges = data
      this.selectedExchange = 1
      this.changeExchange(this.selectedExchange)
    })

    BackEndService.GetCurrencies().then((data) => {
      this.currencies = data
    })

    BackEndService.GetIndicators().then((data) => {
      data.forEach(x => delete x.options)
      this.indicators = data

      this.indicator = this.indicators.filter(x => x.name == 'qfl')[0]
    })
  }

  changeExchange(exchangeId: number) {
    BackEndService.GetMarketsForExchange(exchangeId, true).then((data) => {
      data.forEach(x => x.symbol = this.getMarketSymbol(x))
      this.markets = data.sort(this.compareMarkets)
      let market = this.markets.filter(x => x.symbol == 'BTC/EUR')[0]

      this.changeMarket(market.id)
    })
  }

  changeMarket(marketId: number) {
    this.market = this.markets.filter(x => x.id == marketId)[0]
    this.selectedMarket = this.market.id

    console.log(this.market.base + '/' + this.market.quote)
  }

  changeIndicator(indicatorId: number) {
    this.indicator = this.indicators.filter(x => x.id == indicatorId)[0]
    this.selectedIndicator = this.indicator.id
  }

  compareMarkets(market1: Market, market2: Market) {
    if (market1.base < market2.base) {
      return -1
    } else if (market1.base > market2.base) {
      return 1
    } else {
      if (market1.quote < market2.quote) {
        return -1
      } else if (market1.quote > market2.quote) {
        return 1
      } else {
        return 0
      }
    }
  }

  getMarketSymbol(market: Market) {
    let baseCurrency = this.currencies.find(x => x.id == market.base)
    let quoteCurrency = this.currencies.find(x => x.id == market.quote)
    return baseCurrency?.code + '/' + quoteCurrency?.code
  }
}
