import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import Highcharts from 'highcharts'
// import HighchartsVue from 'highcharts-vue'
// import Stock from 'highcharts/modules/stock'
// import darkUnica from "highcharts/themes/dark-unica"

import TradingVue from 'trading-vue-js'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(TradingVue)

// Stock(Highcharts)
// darkUnica(Highcharts)
// Vue.use(HighchartsVue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
