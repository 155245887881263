<script>
import { Overlay } from 'trading-vue-js'
export default {
    name: 'SMI',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'DoDoSt', version: '1.0.0',
                desc: 'Smart money index',
                preset: {
                    name: 'SMI',
                    side: 'offchart',
                    settings: {
                        lineWidth: 0.75,
                        color: '#f7890c'
                    }
                }
            }
        },
        // Here goes your code. You are provided with:
        // { All stuff is reactive }
        // $props.layout -> positions of all chart elements +
        //  some helper functions (see layout_fn.js)
        // $props.interval -> candlestick time interval
        // $props.sub -> current subset of candlestick data
        // $props.data -> your indicator's data subset.
        //  Comes "as is", should have the following format:
        //  [[<timestamp>, ... ], ... ]
        // $props.colors -> colors (see TradingVue.vue)
        // $props.cursor -> current position of crosshair
        // $props.settings -> indicator's custom settings
        //  E.g. colors, line thickness, etc. You define it.
        // $props.num -> indicator's layer number (of All
        // layers in the current grid)
        // $props.id -> indicator's id (e.g. EMA_0)
        // ~
        // Finally, let's make the canvas dirty!
        draw(ctx) {
            const layout = this.$props.layout
            const upper = layout.$2screen(70)
            const lower = layout.$2screen(30)
            const data = this.$props.data
            // RSI values
            ctx.lineWidth = this.line_width
            ctx.strokeStyle = this.color
            ctx.beginPath()
            for (var k = 0, n = data.length; k < n; k++) {
                let p = data[k]
                let x = layout.t2screen(p[0])
                let y = layout.$2screen(p[1])
                ctx.lineTo(x, y)
            }
            ctx.stroke()
            ctx.strokeStyle = this.band_color
            ctx.setLineDash([5]) // Will be removed after draw()
            ctx.beginPath()
            // Fill the area between the bands
            ctx.fillStyle = this.back_color
            ctx.fillRect(0, upper, layout.width, lower - upper)
            // Upper band
            ctx.moveTo(0, upper)
            ctx.lineTo(layout.width, upper)
            // Lower band
            ctx.moveTo(0, lower)
            ctx.lineTo(layout.width, lower)
            ctx.stroke()
        },
        use_for() { return ['SMI'] },
        // calc() {
        //     return {
        //         props: {
        //             length: { def: 12, text: 'Length' }
        //         },
        //         conf: { renderer: 'Range' },
        //         update: ``,
        //         post: `
        //             console.log('post the results')
        //         `,
        //         init: `
        //             console.log('init script')
        //         `
        //     }
        // }

        // https://github.com/tvjsx/trading-vue-js/blob/master/docs/guide/SCRIPTS.md
        
        // y_range(hi, lo) {
        //     return [
        //         Math.max(hi, this.sett.upper || 70),
        //         Math.min(lo, this.sett.lower || 30)
        //     ]
        // }
        // Define internal setting & constants here
        
    },
    computed: {
        sett() {
            return this.$props.settings
        },
        line_width() {
            return this.sett.lineWidth || 0.75
        },
        color() {
            return this.sett.color || '#ec206e'
        },
        band_color() {
            return this.sett.bandColor || '#ddd'
        },
        back_color() {
            return this.sett.backColor || '#381e9c16'
        }
    }
}
</script>