
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import BackEndService from "@/services/BackEndService"
import CurrencyIcon from '@/components/icons/CurrencyIcon.vue'
import PercentText from '@/components/PercentText.vue'
import { Market } from '@/models/Market'
import { Exchange } from '@/models/Exchange'

@Component({
  components: { CurrencyIcon, PercentText }
})
export default class CurrentPriceWidget extends Vue {
  @Prop({required:true}) private exchange!: Exchange
  @Prop({required:true}) private market!: Market
  private percent: number = 0
  private price: number = 0
  private refreshInterval: number = 60000
  
  constructor() {
    super()
    
    this.getData()
    setInterval(() => {
      this.getData()
    }, this.refreshInterval)
  }

  private async getData() {
    BackEndService.GetLatestCandles(
      this.market.id,
      '1d',
      1
    ).then((data) => {
      var firstPrice = data[0].open
      this.price = data[0].close
      this.percent = this.round((this.price / firstPrice * 100) - 100)
    })
  }

  private round(num: number) {
    return Math.round( ( num + Number.EPSILON ) * 100 ) / 100
  }
}
