import HttpClient from '@/services/HttpClient'
import { HttpOptions } from '@/services/HttpClient'
import { Candle } from '@/models/Candle'
import { Exchange } from '@/models/Exchange'
import { Market } from '@/models/Market'
import { Currency } from '@/models/Currency'
import { Indicator } from '@/models/Indicator'
import { Trader } from '@/models/trading/Trader'

interface IBackEndService {
  GetExchanges(): Promise<Exchange[]>
  GetCurrencies(): Promise<Currency[]>
  GetMarkets(active: boolean): Promise<Market[]>
  GetMarketsForExchange(exchangeId: number, active: boolean): Promise<Market[]>
  GetCandles(marketId: number, start: Date, end: Date): Promise<Candle[]>
}

class BackEndService implements IBackEndService {
  constructor() {
  }

  GetExchanges(): Promise<Exchange[]> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.get('/exchanges', httpOptions)
  }

  GetCurrencies(): Promise<Currency[]> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.get('/currencies', httpOptions)
  }

  GetIndicators(): Promise<Indicator[]> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.get('/indicators', httpOptions)
  }

  GetStrategies(): Promise<Trader[]> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.get('/strategies', httpOptions)
  }

  GetMarkets(active: boolean): Promise<Market[]> {
    const httpOptions: HttpOptions = {
      params: {
        'active': active.toString()
      },
      headers: {}
    }

    return HttpClient.get('/markets', httpOptions)
  }  

  GetMarketsForExchange(exchangeId: number, active: boolean): Promise<Market[]> {
    const httpOptions: HttpOptions = {
      params: {
        'exchange': exchangeId.toString(),
        'active': active.toString()
      },
      headers: {}
    }

    return HttpClient.get('/markets', httpOptions)
  }

  GetCandles(marketId: number, start: Date, end: Date): Promise<Candle[]> {
    const httpOptions: HttpOptions = {
      params: {
        'market': marketId.toString(),
        'start': start.toISOString(),
        'end': end.toISOString(),
      },
      headers: {}
    }

    return HttpClient.get('/candles', httpOptions)
  }

  GetLatestCandles(marketId: number, timeframe: string, limit: number): Promise<Candle[]> {
    const httpOptions: HttpOptions = {
      params: {
        'market': marketId.toString(),
        'timeframe': timeframe,
        'limit': limit.toString()
      },
      headers: {}
    }

    return HttpClient.get('/latestCandles', httpOptions)
  }

  GetCandlesWithIndicator(marketId: number, indicatorId: number, timeframe: string, start: Date, end: Date): Promise<Candle[]> {
    const httpOptions: HttpOptions = {
      params: {
        'market': marketId.toString(),
        'indicator': indicatorId.toString(),
        'timeframe': timeframe,
        'start': start.toISOString(),
        'end': end.toISOString(),
      },
      headers: {}
    }

    return HttpClient.get('/candlesWithIndicator', httpOptions)
  }

  GetTraders(): Promise<Trader[]> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.get('/traders', httpOptions)
  }

  GetTrader(traderId: number): Promise<Trader> {
    const httpOptions: HttpOptions = {
      params: {
        'id': traderId.toString()
      },
      headers: {}
    }

    return HttpClient.get<Trader>('/trader', httpOptions).then((trader) => {
      // trader.type = TraderType[trader.typeId]

      return trader
    })
  }

  AddTrader(trader: Trader): Promise<number> {
    const httpOptions: HttpOptions = {
      params: {},
      headers: {}
    }

    return HttpClient.post('/trader', trader, httpOptions)
  }
}

export default new BackEndService()
