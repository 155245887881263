













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class App extends Vue{
  private loading: boolean = true
  // https://austingil.com/3-ways-prepopulate-vue-js-global-stores-state/
  async mounted() {
    await this.$store.dispatch('getCurrencies')
    await this.$store.dispatch('getExchanges')
    await this.$store.dispatch('getMarkets')
    await this.$store.dispatch('getStrategies')
    this.loading = false
  }
}
