





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Trader } from '@/models/trading/Trader'
import { TraderType } from "@/models/trading/TraderType"
import { TraderStatus } from '@/models/trading/TraderStatus'
import PercentText from '@/components/PercentText.vue'
import ExchangeIcon from '@/components/icons/ExchangeIcon.vue'
import CurrencyPairIcon from '@/components/icons/CurrencyPairIcon.vue'
import { Market } from '@/models/Market'
import { Exchange } from '@/models/Exchange'

@Component({
  components: { ExchangeIcon, CurrencyPairIcon, PercentText }
})
export default class TraderInfo extends Vue {
  @Prop({required:true}) private trader!: Trader
  private market!: Market
  private exchange!: Exchange
  
  constructor() {
    super()

  }

  @Watch("trader")
  private async setMarket() {
    this.market = this.$store.getters.marketById(this.trader.marketId)
    this.exchange = this.$store.getters.exchangeById(this.market.exchangeId)
  }

  getType() {
    return TraderType[this.trader.typeId]
  }

  getStatus() {
    return TraderStatus[this.trader.statusId]
  }

  private round(num: number) {
    return Math.round( ( num + Number.EPSILON ) * 100 ) / 100
  }
}
