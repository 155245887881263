







import { Component, Prop, Vue } from 'vue-property-decorator'
import { Currency } from '@/models/Currency'
import CurrencyIcon from '@/components/icons/CurrencyIcon.vue'

@Component({
    components: {CurrencyIcon}
})
export default class CurrencyPairIcon extends Vue {
    @Prop() private baseCurrency!: Currency
    @Prop() private quoteCurrency!: Currency
}
