

































import { Component, Vue } from 'vue-property-decorator'
import CoinChart from '@/components/CoinChart.vue'
import TraderInfo from '@/components/TraderInfo.vue'
import TraderForm from '@/components/TraderForm.vue'
import BackEndService from '@/services/BackEndService'
import { Exchange } from '@/models/Exchange'
import { Market } from '@/models/Market'
import { Currency } from '@/models/Currency'
import { Indicator } from '@/models/Indicator'
import { Trader } from '@/models/trading/Trader'

@Component({
  components: { CoinChart, TraderInfo, TraderForm }
})
export default class Traders extends Vue {
  private traders: Trader [] = []

  private trader: Trader | null = null
  private market: Market | null = null

  $refs!: {
    modal: HTMLFormElement
  }

  get currentTrader() {
    return this.trader != undefined ? this.trader.id : 1
  }

  set currentTrader(traderId: number) {
    this.changeTrader(traderId)
  }

  created () {
    BackEndService.GetTraders().then((data) => {
      this.traders = data
      if (this.traders.length > 0) {
        this.changeTrader(this.traders[0].id)
      }
    })
  }

  changeTrader(traderId: number) {
    BackEndService.GetTrader(traderId).then((data) => {
      this.trader = data
    })
  }

  saveTrader(trader: Trader) {
    this.$refs['modal'].hide()
    BackEndService.AddTrader(trader)
  }
}
