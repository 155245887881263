










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Candle } from "@/models/Candle";
import BackEndService from "@/services/BackEndService";
import { Market } from "@/models/Market";
import { Indicator } from "@/models/Indicator";

@Component
export default class CoinChartHighCharts extends Vue {
  @Prop() private market!: Market;
  @Prop() private indicator!: Indicator;

  private candles: Candle[] = [];
  private chartOptions: any = {
    chart: {
      animation: false,
      marginRight: 10,
      zoomType: "xy",
      // height: 660,
      backgroundColor: '#2a2a2b'
    },
    legend: {
      enabled: true,
    },
    navigator: {
      series: {
        color: "blue",
        fillColor: "white",
        lineColor: "blue",
      },
    },
    plotOptions: {
      candlestick: {
        color: "red",
        upColor: "green",
      },
    },
    // rangeSelector: {
    //   selected: 0,
    // },
    // xAxis: {
    //   type: 'datetime',
    // },
    yAxis: [
      {
        labels: {
          align: "right",
          x: -3,
        },
        title: {
          text: "OHLC",
        },
        height: "60%",
        lineWidth: 2,
        resize: {
          enabled: true,
        },
      },
      {
        labels: {
          align: "right",
          x: -3,
        },
        title: {
          text: "Volume",
        },
        top: "45%",
        height: "15%",
        offset: 0,
        lineWidth: 2,
      },
      {
        labels: {
          align: "right",
          x: -3,
        },
        title: {
          text: "MACD",
        },
        top: "65%",
        height: "35%",
        offset: 0,
        lineWidth: 2,
      }
    ],
    series: [
      {
        type: "candlestick",
        name: "Price",
        data: [1],
      },
      {
        type: "column",
        name: "Volume",
        data: [1],
        yAxis: 1,
        // dataGrouping: {
        //   units: groupingUnits
        // }
      },
    ],
  };

  @Watch("market")
  private async setMarket(newVal: Market, oldVal: Market) {
    // console.log(newVal + "/" + oldVal);

    if (this.indicator != null && ((newVal != null && oldVal == null) || newVal.id != oldVal.id)) {
      this.getData(newVal, this.indicator)
    }
  }

  @Watch("indicator")
  private async setIndicator(newVal: Indicator, oldVal: Indicator) {
    if (this.market != null && ((newVal != null && oldVal == null) || newVal.id != oldVal.id)) {
      this.getData(this.market, newVal)
    }
  }

  private async getData(market: Market, indicator: Indicator) {
    console.log("request candles");

    BackEndService.GetCandlesWithIndicator(
      market.id,
      indicator?.id,
      '1h',
      new Date(2020, 11, 1),
      new Date(2021, 12, 1)
    ).then((data) => {
      this.chartOptions.series.length = 2
      // this.chartOptions.yAxis.length = 2

      this.showStandardData(data)

      if (indicator.name == 'macd') {
        this.showDataWithMacd(data)
      } else if (indicator.name == 'rsi') {
        this.showDataWithRsi(data)
      } else if (indicator.name == 'qfl') {
        this.showDataWithQfl(data)
      }
    });
  }

  private showStandardData(data: Candle[]) {
    this.chartOptions.series[0].data = data.map((x) => [
      Date.parse(x.timestamp),
      x.open,
      x.high,
      x.low,
      x.close,
    ])

    this.chartOptions.series[1].data = data.map((x) => [
      Date.parse(x.timestamp),
      x.volume,
    ])
  }

  private showDataWithRsi(data: Candle[]) {
    this.updateOhlcYAxis(0, 85)
    this.updateVolumeYAxis(85, 15)
    this.updateMacdYAxis(0, 0)
  }

  private showDataWithMacd(data: Candle[]) {
    this.updateOhlcYAxis(0, 60)
    this.updateVolumeYAxis(60, 15)
    this.updateMacdYAxis(75, 25)
    // this.createYAxis('MACD', 65, 35)

    this.chartOptions.series.push({
      type: "line",
      name: "MACD",
      yAxis: 2,
      color: 'blue',
      data: data.map((x) => [
        Date.parse(x.timestamp),
        x.macd,
      ])
    })
    this.chartOptions.series.push({
      type: "line",
      name: "MACD_signal",
      yAxis: 2,
      color: 'red',
      data: data.map((x) => [
        Date.parse(x.timestamp),
        x.macd_signal,
      ])
    })
    this.chartOptions.series.push({
      type: "column",
      name: "MACD_histogramm",
      yAxis: 2,
      color: 'green',
      data: data.map((x) => [
        Date.parse(x.timestamp),
        x.macd_histogram,
      ])
    })
  }

  private showDataWithQfl(data: Candle[]) {
    this.updateOhlcYAxis(0, 85)
    this.updateVolumeYAxis(85, 15)
    this.updateMacdYAxis(0, 0)

    let lastBase = 0
    for (const x of data) {
      if (x.qfl_bases == 0) continue
      if (x.qfl_bases != lastBase) {
        // console.log(x.qfl_bases)
        this.chartOptions.series.push({
          type: 'line',
          marker: {
            enabled: false
          },
          name: 'qfl_bases',
          yAxis: 0,
          showInLegend: false,
          color: 'orange',
          data: [[
            Date.parse(x.timestamp),
            x.qfl_bases,
          ]]
        })

        lastBase = x.qfl_bases
      } else {
        this.chartOptions.series[this.chartOptions.series.length -1].data.push([
          Date.parse(x.timestamp),
          x.qfl_bases,
        ])
      }
    }
  }
  
  private updateOhlcYAxis(topPercent: number, heightPercent: number) {
    this.updateYAxis('OHLC', topPercent, heightPercent)
  }

  private updateVolumeYAxis(topPercent: number, heightPercent: number) {
    this.updateYAxis('Volume', topPercent, heightPercent)
  }

  private updateMacdYAxis(topPercent: number, heightPercent: number) {
    this.updateYAxis('MACD', topPercent, heightPercent)
  }

  private updateYAxis(name: string, topPercent: number, heightPercent: number) {
    let yAxis = this.chartOptions.yAxis.find((x: { title: { text: string; }; }) => x.title.text == name)

    if (topPercent == 0 && heightPercent == 0) {
      yAxis.visible = false
    } else {
      yAxis.visible = true
      yAxis.top = topPercent + '%'
      yAxis.height = heightPercent + '%'
    }
  }

  private createYAxis(text: String, topPercent: number, heightPercent: number) {
    this.chartOptions.yAxis.push({
      labels: {
        align: "right",
        x: -3,
      },
      title: {
        text: text,
      },
      top: topPercent + '%',
      height: heightPercent + '%',
      offset: 0,
      lineWidth: 2
    })
  }
}

