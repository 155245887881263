













































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import BackEndService from "@/services/BackEndService"
import { Market } from "@/models/Market"
import { Indicator } from "@/models/Indicator"

import { DataCube } from 'trading-vue-js'
import Overlays from 'tvjs-overlays'
import XP from 'tvjs-xp'

import QFL from '@/components/QFL.vue'
import SMI from '@/components/SMI.vue'
import CurrencyPairIcon from '@/components/icons/CurrencyPairIcon.vue'
import ExchangeIcon from '@/components/icons/ExchangeIcon.vue'
import { Exchange } from "@/models/Exchange"

// https://github.com/tvjsx/trading-vue-js
@Component({
  components: { CurrencyPairIcon, ExchangeIcon }
})
export default class CoinChart extends Vue {
  @Prop() private exchange!: Exchange
  @Prop() private market!: Market
  @Prop() private indicator!: Indicator
  @Prop() private fav: boolean = false
  private timerangeIndex: number = 0
  private timeranges: string[] = ['1h', '4h', '1d']

  private night: boolean
  private width: number
  private height: number
  private chart: DataCube

  private favorite: boolean = false
 
  $refs!: {
    element: HTMLFormElement
    tvjs: HTMLFormElement
  }

  constructor() {
    super()

    this.height = 0
    this.width = 0
    this.night = true
    this.chart = new DataCube({
      chart: {
          type: "Candles",
          indexBased: false,
          data: [[1630771200000, 30000,31000,29000,30000, 1000], [1630774800000, 30000,31000,29000,30000, 1000]],
          settings: {}
        },
        // onchart: [
        //   {
        //     name: 'QFL',
        //     type: 'QFL',
        //     data: [[1630771200000, 30000], [1630774800000, 42000]],
        //     settings: {}
        //   }
        // ],
        offchart: [          
          {
            name: 'SMI',
            type: 'SMI',
            data: [[1645370344000, 100], [1645481344000, 70], [1645582344000, 20]],
            settings: {}
          },
          {
            name: 'RSI',
            type: 'RSI',
            data: [],
            settings: {
              length: 14,
              upper: 70,
              lower: 30,
              backColor: '#9b9ba316',
              bandColor: '#666'
            }
          },
          {
            name: "MACD",
            type: "MACD",
            data: [],
            settings: {
              fast: 12,
              slow: 26,
              smooth: 9,
              histColors: [
                "#35a776", "#79e0b3", "#e54150", "#ea969e"
              ]
            }
          }
        ]
    })
  }
  
  mounted() {
    this.width = this.$refs.element.clientWidth
    this.height = this.$refs.element.clientHeight - 60
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize(event) {
    this.width = this.$refs.element.clientWidth
    this.height = this.$refs.element.clientHeight - 60
  }

  get colors() {
    return this.night ? {
      back: '#2a2a2b',
      grid: '#3e3e3e',
      text: '#35a776',
      cross: '#dd64ef',
      candle_dw: '#e54077',
      wick_dw: '#e54077'
    } : {
      back: '#fff',
      grid: '#eee',
      text: '#333',
      candle_dw: 'black',
      wick_dw: 'black'
    }
  }

  data() {
    return {
      ext: Object.values(XP),
      overlays: [QFL, SMI].concat(Object.values(Overlays)),
      night: true
    }
  }

  @Watch("market")
  private async setMarket(newVal: Market, oldVal: Market) {
    if (this.indicator != undefined && newVal != undefined && (oldVal == undefined || newVal.id != oldVal.id)) {
      this.getData(newVal, this.indicator)
    }
  }

  @Watch("indicator")
  private async setIndicator(newVal: Indicator, oldVal: Indicator) {
    if (this.market != null && ((newVal != null && oldVal == null) || newVal.id != oldVal.id)) {
      if (!['macd', 'rsi'].includes(newVal.name)) {
        this.getData(this.market, newVal)
      }
    }
  }

  @Watch("fav")
  private async setFavorite(newVal: boolean) {
    this.favorite = newVal
  }

  private async setTimerange(timerange: string, index: number) {
    this.timerangeIndex = index
    this.getData(this.market, this.indicator)
  }

  private async changeFavorite() {
    this.favorite = !this.favorite
    this.$emit('clicked', this.market, this.favorite)
  }

  private async getData(market: Market, indicator: Indicator) {
    if (market == undefined || indicator == undefined) return

    let now = new Date()
    let oneYear = new Date()
    oneYear.setFullYear(now.getFullYear() - 1)

    BackEndService.GetCandlesWithIndicator(
      market.id,
      indicator?.id,
      this.timeranges[this.timerangeIndex],
      oneYear,
      now
    ).then((data) => {
      let ohlcv = data.map((x) => [
        Date.parse(x.timestamp),
        x.open,
        x.high,
        x.low,
        x.close,
        x.volume
      ])

      // let qfl = data.map((x) => [
      //   Date.parse(x.timestamp),
      //   x.qfl,
      //   x.qfl_bases
      // ])

      let smi = data.map((x) => [
        Date.parse(x.timestamp),
        x.smi
      ])

      this.chart.set('chart.data', ohlcv)
      // this.chart.set('onchart.QFL.data', qfl)
      this.chart.set('offchart.SMI.data', smi)

      this.$refs.tvjs.resetChart()
      // this.$refs.tvjs.setRange(Date.parse('2021-06-03T13:00:00.000Z'), Date.parse('2021-10-03T13:00:00.000Z'))
    });
  }
}
