




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Exchange } from '@/models/Exchange'

@Component
export default class ExchangeIcon extends Vue {
    @Prop() private exchange!: Exchange

    private iconUrl: string = 'https://s2.coinmarketcap.com/static/img/exchanges/64x64/'

    getScr() {
        return `${this.iconUrl}${this.exchange.iconId}.png`
    }
}
