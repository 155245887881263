<script>
import { Overlay } from 'trading-vue-js'
export default {
    name: 'QFL',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'DoDoSt', version: '1.0.0',
                desc: 'Quickfingers Luc Base Breaking Indicator',
                preset: {
                    name: 'QFL',
                    side: 'onchart',
                    settings: {
                        lineWidth: 0.75,
                        color: '#f7890c'
                    }
                }
            }
        },
        // Here goes your code. You are provided with:
        // { All stuff is reactive }
        // $props.layout -> positions of all chart elements +
        //  some helper functions (see layout_fn.js)
        // $props.interval -> candlestick time interval
        // $props.sub -> current subset of candlestick data
        // $props.data -> your indicator's data subset.
        //  Comes "as is", should have the following format:
        //  [[<timestamp>, ... ], ... ]
        // $props.colors -> colors (see TradingVue.vue)
        // $props.cursor -> current position of crosshair
        // $props.settings -> indicator's custom settings
        //  E.g. colors, line thickness, etc. You define it.
        // $props.num -> indicator's layer number (of All
        // layers in the current grid)
        // $props.id -> indicator's id (e.g. EMA_0)
        // ~
        // Finally, let's make the canvas dirty!
        draw(ctx) {
            const layout = this.$props.layout

            ctx.lineWidth = 3
            ctx.strokeStyle = 'yellow'

            ctx.beginPath()

            var lastBase = 0
            for (var p of this.$props.data) {
                if (p[2] == 0) continue
                if (lastBase != p[2]) {
                    ctx.stroke()
                    ctx.beginPath()
                    lastBase = p[2]
                }

                let x = layout.t2screen(p[0])
                let y = layout.$2screen(p[2])
                ctx.lineTo(x, y)
            }

            ctx.stroke()
        },
        use_for() { return ['QFL'] },
        // calc() {
        //     return {
        //         props: {
        //             length: { def: 12, text: 'Length' }
        //         },
        //         // conf: { renderer: 'Spline' },
        //         update: `
        //             return close
        //         `
        //     }
        // }
    }
}
</script>