




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Currency } from '@/models/Currency'

@Component
export default class CurrencyIcon extends Vue {
    @Prop() private currency!: Currency

    private iconUrl: string = 'https://s2.coinmarketcap.com/static/img/coins/64x64/'

    getScr() {
        return `${this.iconUrl}${this.currency.iconId}.png`
    }
}
