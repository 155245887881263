












































import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import CoinChart from '@/components/CoinChart.vue'
import CurrentPriceWidget from '@/components/CurrentPriceWidget.vue'
import BackEndService from '@/services/BackEndService'
import { Exchange } from '@/models/Exchange'
import { Market } from '@/models/Market'
import { Currency } from '@/models/Currency'
import { Indicator } from '@/models/Indicator'

@Component({
  components: { CoinChart, CurrentPriceWidget, draggable }
})
export default class Markets extends Vue {
  private exchanges: Exchange[] = []
  private currencies: Currency[] = []
  private markets: Market[] = []
  private indicators: Indicator[] = []

  private exchange: Exchange | null = null
  private market: Market | null = null
  private favoriteMarkets: Market[] = []


  private indicator: Indicator | null = null

  private drag: boolean = false

  get currentExchange() {
    return this.exchange != undefined ? this.exchange.id : 1
  }

  set currentExchange(exchangeId: number) {
    const exchange = this.exchanges.find(x => x.id == exchangeId)
    if (exchange != undefined) this.exchange = exchange
  }

  get currentMarket() {
    return this.market != undefined ? this.market.id : 1
  }

  set currentMarket(marketId: number) {
    const market = this.getMarketById(marketId)
    if (market != undefined) this.market = market
  }

  async mounted () {
    this.exchanges = await this.$store.getters.allExchanges

    
    this.loadFavoriteMarkets()

    BackEndService.GetIndicators().then((data) => {
      data.forEach(x => delete x.options)
      this.indicators = data

      this.indicator = this.indicators.filter(x => x.name == 'smi')[0]
    })

    this.initSelection()
  }

  initSelection() {
    const market = this.loadLastMarket()
    if (market) {
      this.market = market
      const exchange = this.getExchangeByMarket(market)
      if (exchange) {
        this.exchange = exchange
      }
    } 
    
    if (!this.exchange || !this.market) {
      this.exchange = this.exchanges[0]
    }

    this.markets = this.$store.getters.marketsByExchange(this.exchange.id)

    if (!this.market) {
      this.market = this.markets[0]
    }
  }

  dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  changeExchange(exchangeId: number) {
    this.markets = this.$store.getters.marketsByExchange(exchangeId)
    this.market = this.markets[0]

    this.saveLastMarket(this.market.id)
  }

  changeMarket(marketId: number) {
    this.saveLastMarket(marketId)
  }

  favoriteClicked(market: Market) {
    this.market = market
    const exchange = this.getExchangeByMarket(market)
    if (exchange) {
      this.exchange = exchange
      this.markets = this.$store.getters.marketsByExchange(this.exchange?.id)
      this.saveLastMarket(market.id)
    }
  }

  changeIndicator(indicatorId: number) {
    this.indicator = this.indicators.filter(x => x.id == indicatorId)[0]
  }

  getExchangeByMarket(market: Market) {
    return this.$store.getters.exchangeById(market.exchangeId)
  }

  getMarketById(marketId: number) {
    return this.$store.getters.marketById(marketId)
  }

  favoriteChanged(market: Market, active: boolean) {
    if (this.favoriteMarkets.includes(market) != active) {
      if (active) {
        this.favoriteMarkets.push(market)
      } else {
        const index = this.favoriteMarkets.indexOf(market)
        this.favoriteMarkets.splice(index, 1)
      }
    }

    this.saveFavoriteMarkets()
  }

  startDragFavorite() {
    this.drag = true
  }

  endDragFavorite() {
    this.drag = false
    this.saveFavoriteMarkets()
  }

  loadFavoriteMarkets() {
    if (localStorage.favortiteMarketIds) {
      const favortiteMarketIds = localStorage.favortiteMarketIds.split(',')

      for (const favortiteMarketId of favortiteMarketIds) {
        const market = this.$store.getters.marketById(favortiteMarketId)
        if (market == undefined) continue
        this.favoriteMarkets.push(market)
      }
    }
  }

  saveFavoriteMarkets() {
    localStorage.favortiteMarketIds = this.favoriteMarkets.map(x => x.id)
  }

  loadLastMarket() {
    if (localStorage.lastMarket) {
      const x = parseInt(localStorage.lastMarket)
      const y = this.$store.getters.marketById(x)
      return y
    }
  }

  saveLastMarket(marketId: number) {
    localStorage.lastMarket = marketId
  }
}
