














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Trader } from '@/models/trading/Trader'
import { TraderType } from "@/models/trading/TraderType"
import { Exchange } from '@/models/Exchange'
import { Market } from '@/models/Market'
import { Strategy } from '@/models/strategy'

@Component
export default class TraderForm extends Vue {
  private exchanges: Exchange[] = []
  private markets: Market[] = []
  private strategies: Strategy[] = []

  private exchangeId: number = 0
  private trader: Trader = {
    id: 0,
    name: '',
    typeId: 0, 
    statusId: 0,
    marketId: 0,
    strategyId: 1,
    startTime: new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()),
    stopLoss: 10,
    fee: 0.1,
    avrBuySellValue: 50,
    discord: false,
    portfolio: [{currencyId: 0, amount: 0}, {currencyId: 0, amount: 0}]
  }

  created () {
    this.exchanges = this.$store.getters.allExchanges
    this.exchangeId = this.exchanges[0].id
    this.changeExchange(this.exchangeId)

    this.strategies = this.$store.getters.allStrategies
  }

  get allTypes() {
    return Object.entries(TraderType)
      .filter(x => isNaN(x[0] as any))
      .map(x => ({name: x[0], id: x[1]}))
  }

  get baseCurrencyName() {
    const market = this.markets.find(x => x.id == this.trader.marketId)
    
    const id = market?.baseCurrency?.id
    this.trader.portfolio[0].currencyId = id ? id : 0

    return market?.baseCurrency?.code
  }

  get quoteCurrencyName() {
    const market = this.markets.find(x => x.id == this.trader.marketId)

    const id = market?.quoteCurrency?.id
    this.trader.portfolio[1].currencyId = id ? id : 0

    return market?.quoteCurrency?.code
  }

  get baseCurrencyAmount() {
    return this.trader.portfolio[0].amount
  }
  
  get quoteCurrencyAmount() {
    return this.trader.portfolio[1].amount
  }

  set baseCurrencyAmount(amount: number) {
    this.trader.portfolio[0].amount = amount
  }

  set quoteCurrencyAmount(amount: number) {
    this.trader.portfolio[1].amount = amount
  }

  changeExchange(exchangeId: number) {
    this.markets = this.$store.getters.marketsByExchange(exchangeId)
    this.trader.marketId = this.markets[0].id
  }

  onSubmit() {
    this.$emit('onSubmit', this.trader)
  }
}
